
import { utagViewMixin } from "~/mixins";

const EVENT_CATEGORY = "static page";
const EVENT_ACTION = "view";

export default {
  mixins: [utagViewMixin],

  props: {
    links: { type: Array, default: () => [] },
    keyPrefix: { type: String, required: true },
  },

  methods: {
    handleClick({ link, link_label: linkLabel }) {
      if (link?._meta?.uid !== "snooze-profiler") {
        return;
      }

      this.utagClick({
        event_label: this.$prismic.asText(linkLabel),
        event_category: EVENT_CATEGORY,
        event_action: EVENT_ACTION,
      });
    },
  },
};
